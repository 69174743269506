import LoyaltyIcon from "@material-ui/icons/Loyalty";

//import Rediscover from "views/Promos/Rediscover";
import ProductPowerSaleChallenge from "views/Promos/ProductPowerSaleChallenge";
import SindoPlus from "views/Promos/SindoPlus";
import TourGuideSupportingFund from "views/Promos/TourGuideSupportingFund";

const promos = () => {
  let views = [];
  /*views.push({
    path: "/Promos/Rediscover",
    name: "Rediscover Singapore",
    mini: "RS",
    component: Rediscover,
    layout: "/admin"
  });*/
  views.push({
    path: "/Promos/ProductPowerSaleChallenge",
    name: "Product Power Sale Challenge",
    mini: "TU",
    component: ProductPowerSaleChallenge,
    layout: "/admin"
  });
  views.push({
    path: "/Promos/TourGuideSupportingFund",
    name: "Tour Guide Supporting Fund",
    mini: "WP",
    component: TourGuideSupportingFund,
    layout: "/admin"
  });
  views.push({
    path: "/Promos/SindoPlus",
    name: "Sindo Plus",
    mini: "WP",
    component: SindoPlus,
    layout: "/admin"
  });
  return [
    {
      collapse: true,
      name: "Promos",
      icon: LoyaltyIcon,
      state: "promoCollapse",
      views: views
    }
  ];
};

export { promos };
