import React from "react";
import { Link } from "react-router-dom";
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  /*
  const openLink = (link) => {
    window.open(link,'_blank');
  }
  */
  return (
    <div>
      <h3>Hot Deals</h3>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={
                  "/dashboard/202409100859_ferry_schedule_and_visa_information.jpg"
                }
                alt="Schedule & Visa Information"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Ferry Schedule & Visa Information
              </h4>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/visa-information",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={"/dashboard/202409100900_group_charter_enquiry.png"}
                alt="Group / Charter Enquiry"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Group / Charter Enquiry
              </h4>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/charter-enquiry",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={
                  "/dashboard/202409100901_attraction_and_ferry_tickets_to_johor.jpg"
                }
                alt="Attraction & Ferry Tickets to Johor"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Attraction & Ferry Tickets to Johor
              </h4>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/attraction-and-malaysia-ferry-tickets",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={
                  "/dashboard/202409100902_attraction_and_ferry_to_singapore.jpg"
                }
                alt="Attraction & Ferry Tickets to Singapore"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Attraction & Ferry Tickets to Singapore
              </h4>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/attraction-and-malaysia-ferry-tickets",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={"/dashboard/202409100904_fleet_information.jpg"}
                alt="Fleet Information"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>Fleet Information</h4>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/our-fleet",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <Link to={"/admin/promos/sindoplus"}>
                <img
                  src={"/dashboard/202409100905_sindo_plus_1.jpg"}
                  alt="Sindo Plus"
                />
              </Link>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>Sindo Plus</h4>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                <Link to={"/admin/promos/sindoplus"}>Learn More...</Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
